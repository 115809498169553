import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

// External Library
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import { faDribbble, faGithub } from "@fortawesome/free-brands-svg-icons"

// Site components
import Layout from "../components/layout"

interface Props {
  index: number
  children: React.ReactNode
}

// Information componenent about Project
const ProjectInfo = (props: Props) => {
  const variants = {
    visible: i => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: i * 0.1,
        type: "spring",
        damping: 10,
        bounce: 0.2,
      },
    }),
    hidden: { opacity: 0, y: -300, scale: 1 },
  }

  return (
    <motion.div
      className="project-single__info"
      initial="hidden"
      animate="visible"
      variants={variants}
      custom={props.index}>
      <div className="info__container">{props.children}</div>
    </motion.div>
  )
}

export default function WorkTemplate({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout headerType="standard-no-borders">
      <motion.div
        className="project__header"
        initial={{ y: -400 }}
        animate={{ y: 0 }}
        transition={{ type: "spring" }}>
        <div className="project__header-content">
          <div className="container">
            <h2>{frontmatter.title}</h2>
            <motion.p
              className="project__description"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", delay: 0.4 }}>
              {frontmatter.description}
            </motion.p>
          </div>
        </div>
      </motion.div>
      <div className="container">
        <div className="project-single__infos">
          <ProjectInfo index={0}>
            <span>Prestations</span>
            <ul>
              {frontmatter.type.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </ProjectInfo>
          <ProjectInfo index={1}>
            <span>Livrables</span>
            <ul>
              {frontmatter.deliverables.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </ProjectInfo>
          <ProjectInfo index={2}>
            <span>Stacks</span>
            <ul>
              {frontmatter.stack.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </ProjectInfo>
          <ProjectInfo index={3}>
            <span>Informations</span>
            <ul>
              {frontmatter.website ? (
                <li>
                  <FontAwesomeIcon
                    icon={faLink}
                    style={{ paddingRight: "1em" }}
                  />
                  <a href={frontmatter.website}>Site Internet</a>
                </li>
              ) : (
                ""
              )}
              {frontmatter.dribbble ? (
                <li>
                  <FontAwesomeIcon
                    icon={faDribbble}
                    style={{ paddingRight: "1em" }}
                  />
                  <a href={frontmatter.dribbble}>Dribbble</a>
                </li>
              ) : (
                ""
              )}
              {frontmatter.github ? (
                <li>
                  <FontAwesomeIcon
                    icon={faGithub}
                    style={{ paddingRight: "1em" }}
                  />
                  <a href={frontmatter.github}>Github</a>
                </li>
              ) : (
                ""
              )}
              <li className="project__badge"># {frontmatter.mission}</li>
            </ul>
          </ProjectInfo>
        </div>
        <article className="project__page-single">
          <Img
            className="project__main-img"
            fluid={frontmatter.thumbnail.childImageSharp.fluid}
            alt="main img"
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        mission
        stack
        type
        deliverables
        website
        dribbble
        github
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
      timeToRead
    }
  }
`
